import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import EditContactDialog from "../modals/EditContactDialog";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFormik } from 'formik';
import { connect, useSelector } from "react-redux";
import { appActions } from '../state/actions/app';
import { DropzoneDialog } from "material-ui-dropzone";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from '@material-ui/core/LinearProgress';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'react-router-dom'
import axios from 'axios';
import Box from '@material-ui/core/Box';
import fire from '../firebase';
import "firebase/auth";

const drawerWidth = 240;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const autocompleteService = { current: null };
const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    circularProgress: {
        margin: 'auto',
        marginRight: 'auto',
        marginTop: '250px',
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${ drawerWidth }px)`,
            marginLeft: drawerWidth,
        },
        background: lightBlue[700]
    },
    thumbnail: {
        border: '1px solid gray',
        borderRadius: 10,
        marginTop: 20,
        width: 240,
        height: 240,
        marginLeft: 25,
        [theme.breakpoints.only('xs')]: {
            width: 180,
            height: 180
        }
    },
    title: {
        flexGrow: 1,
    },
    formControl: {
        marginTop: 20,
        marginBottom: 10,
    },
    formGrid: {
        marginTop: 20,
        [theme.breakpoints.only('xs')]: {
            marginTop: 5,
            marginLeft: 25,
            marginRight: 25,
            marginBottom: 10,
        }
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginBottom: theme.spacing(20)
    },
    submit: {
        margin: theme.spacing(3, 0, 10),
    },
    notes: {
        marginTop: 15,
    },
    buttonGrid: {
        marginTop: 5,
        marginBottom: 15,
    },
    progressBar: {
        width: 400,
    },
    saveButton: {
        backgroundColor: lightBlue[500],
        '&:hover': {
            backgroundColor: lightBlue[700],
        },
        marginTop: 20,
        marginBottom: 30,
        alignItems: 'bottom',
        color: 'white'
    },
}));

function PlaceDetails(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const restaurant = useSelector(state => state.app.restaurantDetails);
    const isLoading = useSelector(state => state.app.isDetailLoading);
    const [openFileDialog, setOpenFileDialog] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState(null);
    const idToken = useSelector(state => state.app.idToken);
    // suggestions
    const cuisines = useSelector(state => state.app.cuisineData);
    const cuisineMap = useSelector(state => state.app.cuisineMap);
    const readableAddress = restaurant.address.street ? (`${ restaurant.address.street }, ${ restaurant.address.city }, ${ restaurant.address.state }, ${ restaurant.address.zipcode }, ${ restaurant.address.country }`) : '';
    const [value, setValue] = React.useState(readableAddress);
    const [inputValue, setInputValue] = React.useState('');
    const [cuisineNames, setCuisineNames] = React.useState(restaurant.cuisines);
    const [locationData, setLocationData] = React.useState({lat: 0, lon: 0});
    const [options, setOptions] = React.useState([]);
    const formik = useFormik({
        initialValues: restaurant,
        onSubmit: values => {
            let modified = restaurant;
            restaurant.name = values.name;
            restaurant.cuisines = cuisineNames;
            restaurant.location = locationData;
            props.updateRestaurant(modified, idToken);
        },
        enableReinitialize: true
    });

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    useEffect(() => {
        // Your code here
        if (props.restaurantId && !restaurant.id) {
            props.getRestaurantDetails(props.restaurantId, idToken);
        } else {
            props.setDetailLoading(false);
        }

        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [props, idToken, value, inputValue, fetch, restaurant]);

    const validateForm = () => {
        const values = formik.values;
        if (!values.name || (cuisineNames.length === 0 && restaurant.cuisines.length === 0)|| (locationData.lat === 0 && restaurant.location.lat === 0)) {
            return false;
        }
        return true;
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleFileDialogClose = () => {
        setOpenFileDialog(false);
    }

    const handleFileSubmit = (files) => {
        startUpload(files[0]);
        setOpenFileDialog(false);
        setOpenUploadDialog(true);
        setIsUploading(true);
    }

    const handleDone = () => {
        setOpenUploadDialog(false);
        setOpenSaveDialog(false);
    }

    const handleOpen = () => {
        if (restaurant.id) {
            setOpenFileDialog(true);
        } else {
            setOpenSaveDialog(true);
        }
    }

    const handleContactChange = (phoneData) => {
        let modified = restaurant;
        modified.phones = phoneData;
        props.setRestaurantData(modified);
        setOpen(false);
    }

    const startUpload = (file) => {
        // Send data to firebase and then navigate to the detail page
        const fileType = file.type.split('image/')[1];
        const fullPath = restaurant.id + '.' + fileType;
        // Upload File
        var storageRef = fire.storage('chego-restaurant-thumbnails').ref();
        var imageRef = storageRef.child(fullPath);

        var uploadTask = imageRef.put(file);
        uploadTask.on('state_changed', function (snapshot) {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(Math.floor(progress));
        }, function (error) {
            // Handle unsuccessful uploads
            setUploadError(error);
        }, function () {
            // Handle successful uploads on complete
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                // Set downloaded url and insert into database
                let restaurantDetails = formik.values;
                restaurantDetails.thumbnail = downloadURL;
                props.updateRestaurant(restaurantDetails, idToken);
                setOpenUploadDialog(false);
                setIsUploading(false);
            });
        });
    }

    const renderAppBar = () => {
        let pageTitle = 'New Place';
        if (restaurant.id && !isLoading) {
            pageTitle = restaurant.name;
        }
        if (isLoading) {
            pageTitle = 'Loading...';
        }
        return (
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography color="white" variant="h6" className={classes.title}>
                        {pageTitle}
                    </Typography>
                    <IconButton edge="start" color="inherit" aria-label="home" component={Link} to="/">
                        <HomeIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        );
    };

    const renderProfilePicture = () => {
        return (
            <Grid className={classes.paper} item xs={12} sm={5} md={5} elevation={2} square>
                <a href="#" onClick={handleOpen}>
                    <img className={classes.thumbnail} alt={`Logo representing ${restaurant.name}`} src={restaurant.thumbnail} />
                </a>
            </Grid>
        );
    }

    const renderAddressField = () => {
        let fieldValue = value;

        if (!value && readableAddress) {
            fieldValue = readableAddress;
        }
        return (
            <FormControl fullWidth variant="filled" className={classes.formControl}>
                <Autocomplete
                    id="google-map-search"
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={fieldValue}
                    onChange={(event, newValue) => {
                        setOptions(newValue ? [newValue, ...options] : options);
                        if (newValue && newValue.description) {
                            setValue(newValue);
                            updateCoordinates(newValue.description);
                        }
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} required label="Address" placeholder="Enter street name and city" variant="outlined" fullWidth />
                    )}
                    renderOption={(option) => {
                        let parts = [];
                        if (option && option.structured_formatting) {
                            const matches = option.structured_formatting.main_text_matched_substrings;
                            parts = parse(
                                option.structured_formatting.main_text,
                                matches.map((match) => [match.offset, match.offset + match.length]),
                            );
                        }

                        return (
                            <Grid container alignItems="center">
                                <Grid item>
                                    <LocationOnIcon className={classes.icon} />
                                </Grid>
                                <Grid item xs>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                    <Typography variant="body2" color="textSecondary">
                                        {option.structured_formatting ? option.structured_formatting.secondary_text: ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }}
                />
            </FormControl>
        );
    };

    const renderCuisinesField = () => {
        let cuisineList = [];
        for (const item of restaurant.cuisines) {
            cuisineList.push(
                {
                    value: item,
                    text: cuisineMap[item]
                }
            );
        }
        return (
            <FormControl fullWidth variant="filled" className={classes.formControl}>
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={cuisines}
                    getOptionLabel={(option) => option.text}
                    defaultValue={cuisineList}
                    filterSelectedOptions
                    onChange={(event, newCuisines) => {
                        const values = newCuisines.map(cuisine => cuisine.value);
                        let modified = restaurant;
                        modified.cuisines = values;
                        setCuisineNames(values);
                        props.setRestaurantData(modified);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            required
                            label="Cuisines and Categories"
                            placeholder="Select cuisines and categories"
                        />
                    )}
                />
            </FormControl>
        );
    };

    const renderLoader = () => {
        return (
            <Grid container spacing={2} elevation={5}>
                <CircularProgress className={classes.circularProgress} />
            </Grid>
        );
    };

    const renderSaveDialog = () => {
        return (
            <Dialog
                open={openSaveDialog}
                onClose={handleDone}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Unsaved Changes"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Save this place to add a photo
						</DialogContentText>
                </DialogContent>
                {!isUploading && (
                    <DialogActions>
                        <Button onClick={handleDone} color="primary" autoFocus>
                            OK
							</Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    }

    const updateCoordinates = (query) => {
        const geocodingUrl = "https://maps.googleapis.com/maps/api/geocode/json?key=" +
            googleMapsKey + "&address=" + query;
        axios.get(geocodingUrl)
        .then(function (response) {
            const data = response.data;
            const results = (data && data.results) || [];
            let modified = {};
            modified = restaurant;
            if (results && results.length) {
                const result = results[0];
                const formattedAddress = result.formatted_address;
                const addressComponents = result.address_components;
                // Ex: "2147 Newhall St, Santa Clara, CA 95050, USA"
                const splitAddress = formattedAddress.split(',');
                let parsedAddress = {};
                const length = splitAddress.length;
                parsedAddress.country = splitAddress[length - 1].trimStart();

                for (const component of addressComponents) {
                    if (component.types.indexOf('country') > -1) {
                        // Ex: United States
                        parsedAddress.country = component.long_name;
                        break;
                    }
                }
                const formattedState = splitAddress[length - 2];
                const stateSplit = formattedState.split(' ');
                const stateLength = stateSplit.length;
                parsedAddress.zipcode = stateSplit.splice(stateLength - 1)[0];
                parsedAddress.state = stateSplit.join(' ').trimStart();
                parsedAddress.city = splitAddress[length - 3].trimStart();
                parsedAddress.street = splitAddress[0].trimStart();
                parsedAddress.area = splitAddress.slice(1, length - 3).join().trimStart();
                parsedAddress.notes = query;
                modified.address = parsedAddress;
                const locationResult = result.geometry.location;
                if (locationResult) {
                    modified.location = {
                        lat: locationResult.lat,
                        lon: locationResult.lng
                    };
                }
                modified.modifiedTime = new Date();
                setLocationData(modified.location);
            }
            props.setRestaurantData(modified);
        })
        .catch(function (error) {
            // Stop spinner
            console.log(error);
        });
    };

    const renderForm = () => {
        const isFormValid = validateForm();
        return (
            <Grid container className={classes.mainView} spacing={2} elevation={5}>
                {renderProfilePicture()}
                <Grid className={classes.formGrid} item xs={12} sm={6} md={6} elevation={2} square>
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <TextField variant="outlined" margin="normal" required fullWidth
                            id="name" label="Name" name="name" autoComplete="name"
                            onChange={formik.handleChange} value={formik.values.name} />
                        <React.Fragment>
                            {renderAddressField()}
                            {renderCuisinesField()}
                        </React.Fragment>
                        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                            <EditContactDialog restaurant={restaurant} onClose={handleClose} onContactDataChanged={handleContactChange}/>
                        </Dialog>
                        <Button type="submit" fullWidth variant="contained" disabled={!isFormValid}
                            className={classes.saveButton} onClick={formik.handleSubmit}>Save</Button>
                    </form>
                </Grid>
            </Grid>
        );
    }

    const renderUploadingDialog = () => {
        return (
            <Dialog
                open={openUploadDialog}
                onClose={handleDone}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Uploading
                </DialogTitle>
                <DialogContent classNames={classes.progressBar}>
                    {!uploadError &&
                        <React.Fragment>
                            <Box display="flex" alignItems="center">
                                <Box width="100%" mr={1}>
                                    <LinearProgress variant="determinate" value={uploadProgress} />
                                </Box>
                                <Box minWidth={35}>
                                    <Typography variant="body2" color="textSecondary">{`${ Math.round(
                                        uploadProgress,
                                    ) }%`}</Typography>
                                </Box>
                            </Box>
                            <DialogContentText id="alert-dialog-description">
                            </DialogContentText>
                        </React.Fragment>
                    }
                    {uploadError &&
                        <DialogContentText id="alert-dialog-description">
                            Error
                        </DialogContentText>
                    }
                </DialogContent>
            </Dialog>
        );
    }

    if (!props.restaurantId && restaurant.id) {
        const url = `/menucards/${restaurant.id}`;
        return (
            <Redirect to={url}/>
        );
    }

    return (
        <div>
            {renderAppBar()}
            <DropzoneDialog
                open={openFileDialog}
                onSave={handleFileSubmit}
                dropzoneText=" "
                dialogTitle="Add Place Photo"
                fullWidth={true}
                acceptedFiles={["image/jpeg", "image/png"]}
                showPreviews={true}
                filesLimit={1}
                onClose={handleFileDialogClose}
            />
            {renderUploadingDialog()}
            {renderSaveDialog()}
            {isLoading && renderLoader()}
            {!isLoading && renderForm()}
        </div>
    );
}

export default connect(null, appActions)(PlaceDetails);