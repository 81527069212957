const cuisines = [
  "Afghan",
  "African",
  "Albanian",
  "Algerian",
  "Alsatian",
  "American",
  "Armenian",
  "Argentine",
  "Asian",
  "Australian",
  "Austrian",
  "Auvergne",
  "Bagels",
  "Bakery",
  "Bangladeshi",
  "Bar",
  "Barbecue",
  "Belgian",
  "Bistro",
  "Brasserie",
  "Brazilian",
  "Breakfast",
  "British",
  "Brunch",
  "Bubble Tea",
  "Buffet",
  "Burgers",
  "Burgundy",
  "Burmese",
  "Cafe",
  "Cajun",
  "Californian",
  "Calzones",
  "Cambodian",
  "Cantonese",
  "Caribbean",
  "Casual",
  "Catalonian",
  "Cheesesteaks",
  "Chicken",
  "Chilean",
  "Chinese",
  "Chowder",
  "Cideries",
  "Coffee",
  "Colombian",
  "Contemporary",
  "Continental",
  "Corsica",
  "Creole",
  "Crepes",
  "Cuban",
  "Cupcakes",
  "Czech",
  "Deli",
  "Desserts",
  "Dim Sum",
  "Diner",
  "Dominican",
  "Donuts",
  "Dumplings",
  "Dutch",
  "Eastern European",
  "Eclectic",
  "Egyptian",
  "English",
  "Ethiopian",
  "Ecuadorean",
  "European",
  "Falafel",
  "Fast Food",
  "Filipino",
  "Fish and Chips",
  "Fondue",
  "French",
  "Frozen Yogurt",
  "Fusion",
  "Gastropub",
  "Gelato",
  "German",
  "Greek",
  "Grill",
  "Gyros",
  "Haitian",
  "Hamburgers",
  "Halal",
  "Hand Rolls",
  "Hawaiian",
  "Healthy",
  "Himalayan",
  "Honduran",
  "Hot Dogs",
  "Hot Pot",
  "Ice Cream",
  "Indian",
  "Indonesian",
  "International",
  "Iranian",
  "Irish",
  "Israeli",
  "Italian",
  "Izakaya",
  "Jamaican",
  "Japanese",
  "Juices",
  "Korean",
  "Korean Barbeque",
  "Kosher",
  "Latin",
  "Latin American",
  "Lebanese",
  "Lyonnais",
  "Malaysian",
  "Mangolian",
  "Mediterranean",
  "Mexican",
  "Middle Eastern",
  "Milkshakes",
  "Mongolian",
  "Moroccan",
  "Nepalese",
  "Noodle Bar",
  "Norwegian",
  "Organic",
  "Oysters",
  "Pacific Rim",
  "Pakistani",
  "Pan Asian",
  "Pancakes",
  "Pasta",
  "Pastries",
  "Patisseries",
  "Persian",
  "Peruvian",
  "Pho",
  "Pizza",
  "Poke",
  "Polish",
  "Polynesian",
  "Portuguese",
  "Pretzels",
  "Provençal",
  "Pub Food",
  "Puerto Rican",
  "Ramen",
  "Raw",
  "Restaurant",
  "Ribs",
  "Russian",
  "Salad",
  "Salvadoran",
  "Sandwiches",
  "Savoy",
  "Scandinavian",
  "Seafood",
  "Senegalese",
  "Shabu-shabu",
  "Shanghainese",
  "Sichuan",
  "Singaporean",
  "Smoothies",
  "Snacks",
  "Soul Food",
  "Soup",
  "South American",
  "South African",
  "South Indian",
  "South Pacific",
  "Southern",
  "Southwestern",
  "Spanish",
  "Steak",
  "Steakhouse",
  "Subs",
  "Sushi",
  "Sweets",
  "Taiwanese",
  "Tapas",
  "Tea",
  "Teppanyaki",
  "Tex Mex",
  "Thai",
  "Tibetan",
  "Traditional",
  "Tunisian",
  "Turkish",
  "Udon Noodle",
  "Ukrainian",
  "Vegan",
  "Vegetarian",
  "Venezuelan",
  "Vietnamese",
  "Wine Bar",
  "Wings",
  "Wraps",
  "Yakitori"
];

export default cuisines;
