import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import lightBlue from '@material-ui/core/colors/lightBlue';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        background: lightBlue[700]
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    toolbar: theme.mixins.toolbar,
    formGrid: {
        marginLeft: 25,
        marginRight: 25,
        alignItems: 'center',
    },
}));

export default function MenuCategoryDialog(props) {
    const classes = useStyles();
    const categoryObj = props.category || {
        name: '',
        description: '',
        items: [],
        position: props.position,
        section_number: Math.random().toString()
    };
    const formik = useFormik({
        initialValues: categoryObj,
        onSubmit: values => {
            if (props.category) {
                // Editing mode
                props.onCategoryEdit(values);
            } else {
                props.onCategoryCreate(values);
            }
        },
    });

    const validateForm = () => {
        const values = formik.values;
        if (!values.name) {
            return false;
        }
        return true;
    }

    const renderAppBar = () => {
        const isFormValid = validateForm();
        return (
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {props.category ? ('Edit Menu Category - ' + props.category.name): 'Add Menu Category'}
                    </Typography>
                    <Button disabled={!isFormValid} autoFocus color="inherit" onClick={formik.handleSubmit}>
                        done
                    </Button>
                </Toolbar>
            </AppBar>
        );
    };

    return (
        <React.Fragment>
            {renderAppBar()}
            <div className={classes.toolbar} />
            <Grid container className={classes.mainView} spacing={2} elevation={5}>
                <Grid className={classes.formGrid} item xs={12} sm={6} md={6} elevation={2} square>
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <TextField variant="outlined" margin="normal" required fullWidth
                            id="name" label="Name" name="name" autoComplete="name"
                            onChange={formik.handleChange} value={formik.values.name} />
                        <TextField variant="outlined" margin="normal" fullWidth multiline rows={4}
                            id="description" label="Description" name="description" autoComplete="description"
                            onChange={formik.handleChange} value={formik.values.description} />
                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
