export default class Restaurant {
    id;
    name = '';
    cuisines = [];
    thumbnail = 'https://storage.googleapis.com/chego-manager.appspot.com/MissingMenuCard.png';
    address = {
        "street": "",
        "area": "",
        "city": "",
        "state": "",
        "country": "",
        "zipcode": '',
        "notes": ""
    };
    location = {
        "lat": 0,
        "lon": 0
    };
    sections = [];
    modifiedTime = new Date();
}