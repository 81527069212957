const firebaseConfig = {
  apiKey: "AIzaSyAF7UvoAogBhBbNuS3vnlvWKMHTX27X900",
  authDomain: "chego-manager.firebaseapp.com",
  databaseURL: "https://chego-manager.firebaseio.com",
  projectId: "chego-manager",
  storageBucket: "chego-manager.appspot.com",
  messagingSenderId: "392037635202",
  appId: "1:392037635202:web:1d48513294d8c9755cd09b"

};
export default firebaseConfig;