import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import Overview from "./pages/Overview";
import AppNavigation from "./AppNavigation";
import SignInPage from "./pages/SignInPage";
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import "firebase/auth";
import firebase from "firebase/app";
import firebaseConfig from './firebaseConfig';

function App() {
	return (
		<FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
			<IfFirebaseAuthed>
				<main>
					<Router>
						<Switch>
							<Route exact path='/overview' component={Overview} />
							<Route exact path='/' component={HomePage} />
							<Route exact path='/menucards/:id/' component={AppNavigation} />
							<Route exact path='/new-menu-card' component={AppNavigation} />
						</Switch>
					</Router>
				</main>
			</IfFirebaseAuthed>
			<IfFirebaseUnAuthed>
				<main>
					<Router>
						<Switch>
							<Route exact path='/' component={SignInPage} />
							<Route exact path='/overview' component={Overview} />
						</Switch>
					</Router>
				</main>
			</IfFirebaseUnAuthed>
		</FirebaseAuthProvider>
	);
}

export default App;
