import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CodeIcon from '@material-ui/icons/Code';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HistoryIcon from '@material-ui/icons/History';
import grey from '@material-ui/core/colors/grey';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import History from './History';
import Acknowledgements from '../acknowledgements/Acknowledgements';
import "firebase/auth";
import firebase from "firebase/app";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${ drawerWidth }px)`,
            marginLeft: drawerWidth,
        },
        background: lightBlue[700]
    },  
    primaryButton: {
        margin: theme.spacing(3, 0, 10),
        backgroundColor: lightBlue[500],
        "&:hover": {
            backgroundColor: lightBlue[700],
        },
        color: "white",
    },
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    listView: {
        background: grey[200],
        minHeight: '700px',
        minWidth: '250px'
    },
    itemView: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1.5)
        },
        marginTop: theme.spacing(1.5),
        minHeight: '700px'
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

const handleLogOut = () => {
    firebase.app().auth().signOut();
}

const sections = [
    {
        name: 'Account',
        icon: <AccountCircleIcon />
    },
    {
        name: 'History',
        icon: <HistoryIcon />
    },
    {
        name: 'Acknowledgements',
        icon: <CodeIcon />
    }
];

export default function AppSettings() {
    const classes = useStyles();
    const theme = useTheme();
    const [activeList, setActiveList] = React.useState(0);
    const mediaQuery = useMediaQuery(theme.breakpoints.up('sm'));

    const handleBackClick = () => {
        setActiveList(-1);
    };

    const AccountPage = () => {
        const currentUser = firebase.app().auth().currentUser;
        const photoURL = currentUser.photoURL || "https://source.unsplash.com/random/300*300?dessert";
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar variant="circle" className={classes.avatar} src={photoURL}>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {currentUser.displayName || ''}
                    </Typography>
                    <Grid container>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleLogOut}
                                className={classes.primaryButton}
                                component={Link} to="/"
                            >
                                Sign Out
                                    </Button>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        );
    }

    const ListSections = () => {
        return (
            <Grid item xs={12} sm={3} md={3} className={classes.listView}>
                <List component="nav" aria-label="main mailbox folders">
                    {sections.map((section, index) => (
                        <ListItem button selected={activeList === index} onClick={event => setActiveList(index)}>
                            <ListItemIcon>
                                {section.icon}        
                            </ListItemIcon>
                            <ListItemText primary={section.name} />
                        </ListItem>
                    ))}
                </List>
            </Grid>
        );
    }

    let appTitle = "Settings";
    let showMasterView = true;
    let showDetailView = false;

    if (activeList > -1 && !mediaQuery) {
        showMasterView = false;
    }
    // Detail view
    if ((activeList > -1 && !mediaQuery) || mediaQuery) {
        appTitle = (sections[activeList] && sections[activeList].name) || 'Settings';
        showDetailView = true;
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    {activeList > -1 && !mediaQuery &&
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleBackClick}>
                            <ArrowBackIcon />
                        </IconButton>
                    }
                    <Typography color="white" variant="h6" noWrap>
                        {appTitle}
                </Typography>
                </Toolbar>
            </AppBar>
            <Grid container spacing={2}>
                {showMasterView &&
                    ListSections()
                }
                {showDetailView &&
                    <Grid item xs={12} sm={8} md={8} className={classes.itemView}>
                        {activeList === 0 && AccountPage()}
                        {activeList === 1 && <History showEmptyMessage={true}/>}
                        {activeList === 2 && <Acknowledgements />}
                    </Grid>
                }
            </Grid>
            
        </React.Fragment>
    );
}