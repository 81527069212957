import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import "firebase/auth";
import credits from './opensource-libraries';
const ReactMarkdown = require('react-markdown/with-html')

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(20),
    }
}));

export default function Acknowledgements() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <ReactMarkdown source={credits} />
                </div>
            </Container>
        </React.Fragment>
    );
}