import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import ListItemText from '@material-ui/core/ListItemText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import orange from '@material-ui/core/colors/orange';
import grey from '@material-ui/core/colors/grey';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuCategoryDialog from "../modals/MenuCategoryDialog";
import FoodItemDialog from "../modals/FoodItemDialog";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Fab from '@material-ui/core/Fab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import MenuImageAlbum from "./MenuImageAlbum";
import { connect, useSelector } from "react-redux";
import { appActions } from '../state/actions/app';

const drawerWidth = 240;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const FadeTransition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    circularProgress: {
        margin: 'auto',
        marginRight: 'auto',
        marginTop: '250px',
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    placeholder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(1)
    },
    mainView: {
        marginBottom: theme.spacing(15),
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(2)
        },
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    listView: {
        background: grey[200],
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1.5)
        },
        minHeight: '700px'
    },
    itemView: {
        background: grey[200],
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1.5)
        },
        minHeight: '700px'
    },
    categoryFab: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
        backgroundColor: lightBlue[500],
        '&:hover': {
            backgroundColor: lightBlue[700],
        },
        color: 'white'
    },
    itemFab: {
        marginTop: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[600],
        },
        color: 'white'
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${ drawerWidth }px)`,
            marginLeft: drawerWidth,
        },
        background: lightBlue[700]
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    tabs: {
        flexGrow: 1,
    },
    thumbnail: {
        borderRadius: 10,
        width: 320,
        height: 320,
    },
    placeholderText: {
        marginTop: theme.spacing(1),
    },
    placeholder: {
        marginTop: theme.spacing(4),
    },
    primaryButton: {
        backgroundColor: lightBlue[500],
        '&:hover': {
            backgroundColor: lightBlue[700],
        },
        color: 'white'
    },
    addItemButton: {
        backgroundColor: orange[700],
        '&:hover': {
            backgroundColor: orange[800],
        },
        color: 'white'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${ index }`}
            aria-labelledby={`simple-tab-${ index }`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function MenuCardPage(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [isCategoryModalOpen, setCategoryModalOpen] = React.useState(false);
    const [isItemModalOpen, setItemModalOpen] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [activeCategory, setActiveCategory] = React.useState(-1);
    const [activeEditCategory, setActiveEditCategory] = React.useState(-1);
    const [activeItem, setActiveItem] = React.useState(-1);
    const mediaQuery = useMediaQuery(theme.breakpoints.up('sm'));
    const [addCategoryClicked, setAddCategoryClicked] = React.useState(false);
    const [editCategoryClicked, setEditCategoryClicked] = React.useState(false);
    const [addItemClicked, setAddItemClicked] = React.useState(false);
    const [editItemClicked, setEditItemClicked] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [deleteItemIndex, setDeleteItemIndex] = React.useState(-1);
    const restaurant = useSelector(state => state.app.restaurantDetails);
    const [categories, setCategories] = React.useState(restaurant.sections || []);
    const isLoading = useSelector(state => state.app.isMenuLoading);
    const sectionItems = useSelector(state => state.app.detailedMenu);
    const idToken = useSelector(state => state.app.idToken);

    const handleEditCategoryClick = (index) => {
        setActiveEditCategory(index);
        setEditCategoryClicked(true);
        setCategoryModalOpen(true);
    };

    const handleAddCategoryClick = () => {
        setAddCategoryClicked(true);
        setCategoryModalOpen(true);
    };

    const handleEditItemClick = (item, index) => {
        setItemModalOpen(true);
        setActiveItem(index);
        setEditItemClicked(true);
    }

    const handleDeleteDialogOpen = (index) => {
        setOpenDeleteDialog(true);
        setDeleteItemIndex(index);
    }

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    }

    const handleDeleteItem = () => {
        props.deleteFoodItem(sectionItems, deleteItemIndex, idToken);
        setDeleteItemIndex(-1);
        setOpenDeleteDialog(false);
    }

    const handleAddItemClick = () => {
        setItemModalOpen(true);
        setAddItemClicked(true);
    };

    const handleBackClick = () => {
        setActiveCategory(-1);
    };

    const onCategoryClick = (index) => {
        setActiveCategory(index);
        const categoryId = categories[index].section_number;
        props.getFoodItems(props.restaurantId, categoryId, idToken);
    }

    const onCategoryCreate = (category) => {
        let all = categories;
        all.push(category);
        setCategories(all);
        setCategoryModalOpen(false);
        setAddCategoryClicked(false);
        let modified = restaurant;
        modified.sections = all;
        props.updateMenuSections(modified, idToken);
    }

    const onCategoryEdit = (category) => {
        let all = categories;
        all[activeCategory] = category;
        setCategories(all);
        setActiveEditCategory(-1);
        setCategoryModalOpen(false);
        setEditCategoryClicked(false);
        let modified = restaurant;
        modified.sections = all;
        props.updateMenuSections(modified, idToken);
    }

    const onItemCreate = (item) => {
        let items = sectionItems || [];
        setItemModalOpen(false);
        setAddItemClicked(false);
        props.updateFoodItem(item, items, idToken);
    }

    const onItemEdit = (item) => {
        let items = sectionItems || [];
        items[activeItem] = item;
        setItemModalOpen(false);
        setEditItemClicked(false);
        props.updateFoodItem(item, items, idToken);
    }

    const handleCategoryModalClose = () => {
        setCategoryModalOpen(false);
        setAddCategoryClicked(false);
        setEditCategoryClicked(false);
        setActiveEditCategory(-1);
    };

    const handleItemModalClose = () => {
        setItemModalOpen(false);
        setAddItemClicked(false);
        setEditItemClicked(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const AddCategoryPlaceholder = () => {
        return (
            <React.Fragment>
                <CssBaseline />
                <div className={classes.heroContent}>
                    <Container maxWidth="sm">
                        <Grid container spacing={2} justify="center">
                            <img
                                className={classes.thumbnail}
                                alt="A chef standing with a knife and spoon with a cloud in the background."
                                src="https://storage.googleapis.com/chego-web.appspot.com/chef.svg"
                            />
                        </Grid>
                        <div className={classes.heroButtons}>
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        className={classes.primaryButton}
                                        onClick={handleAddCategoryClick}
                                    >
                                        Add Menu Category
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }

    const CategoryList = () => {
        return (
            <Grid className={classes.listView} item xs={12} sm={5} md={5} component={Paper} elevation={2} square>
                <Hidden xsDown implementation="css">
                    <Fab variant="extended" size="small" color="white" aria-label="add"
                        className={classes.categoryFab} onClick={handleAddCategoryClick}>
                        <AddIcon />
                            Add Category
                        </Fab>
                </Hidden>
                <List aria-label="main mailbox folders"
                    subheader={<ListSubheader>Categories</ListSubheader>}>
                    <div className={classes.toolbar} />
                    {categories.map((category, index) => (
                        <ListItem button selected={index === activeCategory} onClick={event => onCategoryClick(index)}>
                            <ListItemText primary={category.name} secondary={category.description} />
                            <ListItemSecondaryAction>
                                <IconButton size="small" edge="start" color="inherit" aria-label="menu" onClick={event => handleEditCategoryClick(index)}>
                                    <EditOutlinedIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        );
    };

    const AddItemPlaceholder = () => {
        var component;
        if (showMasterView) {
            component = Paper;
        }
        return (
            <Grid className={classes.itemView} item xs={12} sm={6} md={6} component={component} elevation={2} square>
                <Grid className={classes.placeholder} container spacing={2} justify="center">
                    <img className={classes.thumbnail}
                        alt="A person thinking about the list of food items"
                        src="https://storage.googleapis.com/chego-web.appspot.com/add_item.svg"
                    />
                </Grid>
                <Grid className={classes.heroButtons} container spacing={2} justify="center">
                    <Grid item>
                        <Button
                            variant="contained"
                            className={classes.addItemButton}
                            onClick={handleAddItemClick}
                        >
                            Add Food Item
                                    </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const SelectItemPlaceholder = (items) => {
        return (
            <Grid className={classes.itemView} item xs={12} sm={6} md={6} component={Paper} elevation={2} square>
                <Grid className={classes.placeholder} container spacing={2} justify="center">
                    <img
                        className={classes.thumbnail}
                        alt="A plate with eggs and bread"
                        src="https://storage.googleapis.com/chego-web.appspot.com/breakfast.svg"
                    />
                    
                </Grid>
                <Grid className={classes.placeholderText} container spacing={2} justify="center">
                    <Grid item>
                        <Typography color="white" variant="h6" className={classes.title}>
                            Select a category
                    </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const ItemList = () => {
        let items = sectionItems || [];
        // Sort foodItems based on position
        items.sort((a, b) => (parseInt(a.position) > parseInt(b.position)) ? 1 : ((parseInt(a.position) < parseInt(b.position)) ? -1 : 0));
        const category = categories[activeCategory];

        if (isLoading && activeCategory > -1) {
            return (
                <Grid className={classes.itemView} item xs={12} sm={6} md={6} component={Paper} elevation={2} square>
                    {renderLoader()}
                </Grid>
            );
        }

        if (categories.length > 0 && activeCategory === -1) {
            return (
                <React.Fragment>
                    {SelectItemPlaceholder()}
                </React.Fragment>
            );
        }

        if (categories.length > 0 && items.length === 0 && activeCategory > -1) {
            return (
                <React.Fragment>
                    {AddItemPlaceholder(items)}
                </React.Fragment>
            );
        }

        return (
            <Grid className={classes.itemView} item xs={12} sm={6} md={6} component={Paper} elevation={2} square>
                <React.Fragment>
                    <Hidden xsDown implementation="css">
                        <Fab variant="extended" size="small" color="primary" aria-label="add"
                            className={classes.itemFab} onClick={handleAddItemClick}>
                            <AddIcon />
                                Add Food Item
                            </Fab>
                    </Hidden>
                    <List component="nav" aria-label="main mailbox folders" subheader={<ListSubheader>{category.name}</ListSubheader>}>
                        <div className={classes.toolbar} />
                        {items.map((item, index) => (
                            <ListItem button onClick={event => handleEditItemClick(item, index)}>
                                <ListItemText primary={item.name}/>
                                <ListItemSecondaryAction>
                                    <IconButton size="small" edge="start" color="inherit" aria-label="menu" onClick={event => handleDeleteDialogOpen(index)}>
                                        <DeleteOutlinedIcon/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </React.Fragment>
            </Grid>
        );
    }

    const renderLoader = () => {
        return (
            <Grid container spacing={2} elevation={5}>
                <CircularProgress className={classes.circularProgress} />
            </Grid>
        );
    }

    const renderDeleteDialog = () => {
        let dialogTitle = 'Delete';
        if (deleteItemIndex > -1) {
            const item = sectionItems[deleteItemIndex];
            dialogTitle = 'Delete ' + item.name + '?'
        }
        return (
            <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteDialogClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this item?
						</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color="primary" autoFocus>
                        CANCEL
                    </Button>
                    <Button onClick={handleDeleteItem} color="secondary" autoFocus>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    const renderDialogs = (showMasterView, showDetailView) => {
        var transition = Transition;
        if (showMasterView && showDetailView) {
            // Don't use the slideup transition on large screens
            transition = FadeTransition;
        }
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Dialog fullScreen open={isCategoryModalOpen} onClose={handleCategoryModalClose} TransitionComponent={transition}>
                        {addCategoryClicked &&
                            <MenuCategoryDialog position={categories.length} onCategoryCreate={onCategoryCreate} onClose={handleCategoryModalClose} />
                        }
                        {editCategoryClicked &&
                            <MenuCategoryDialog category={categories[activeEditCategory]} onCategoryEdit={onCategoryEdit} onClose={handleCategoryModalClose} />
                        }
                    </Dialog>
                    <Dialog fullScreen open={isItemModalOpen} onClose={handleItemModalClose} TransitionComponent={transition}>
                        {addItemClicked &&
                            <FoodItemDialog position={sectionItems.length} section={categories[activeCategory].section_number}
                            restaurant={restaurant} onItemCreate={onItemCreate} onClose={handleItemModalClose} />
                        }
                        {editItemClicked &&
                            <FoodItemDialog item={sectionItems[activeItem]} restaurant={restaurant}
                                onItemEdit={onItemEdit} onClose={handleItemModalClose} />
                        }
                    </Dialog>
                </div>
            </Container>
        );
    }

    let appTitle = 'Menu Card';
    let showMasterView = true;
    let showDetailView = false;

    if (activeCategory > -1 && !mediaQuery) {
        appTitle = categories[activeCategory].name;
        showMasterView = false;
    }
    // Detail view
    if ((activeCategory > -1 && !mediaQuery && !editCategoryClicked) || mediaQuery) {
        showDetailView = true;
    }

    return (
        <div>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    {activeCategory > -1 && !mediaQuery &&
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleBackClick}>
                            <ArrowBackIcon />
                        </IconButton>
                    }
                    <Typography color="white" variant="h6" className={classes.title}>
                        {appTitle}
                    </Typography>
                    {value === 0 &&
                        <Hidden smUp implementation="css">
                            <IconButton edge="start" color="inherit" aria-label="menu"
                                onClick={showMasterView ? handleAddCategoryClick : handleAddItemClick}>
                                <AddIcon />
                            </IconButton>
                        </Hidden>
                    }
                </Toolbar>
            </AppBar>
            {showMasterView &&
                <Paper className={classes.tabs}>
                    <Tabs value={value} onChange={handleChange} indicatorColor="primary"
                        textColor="primary" centered>
                        <Tab label="Categories" />
                        <Tab label="Photos" />
                    </Tabs>
                </Paper>
            }
            <TabPanel value={value} index={0}>
                {renderDeleteDialog()}
                {renderDialogs(showMasterView, showDetailView)}
                {categories.length === 0 && 
                    <React.Fragment>
                        {AddCategoryPlaceholder()}
                    </React.Fragment>
                }
                {categories.length > 0 &&
                    <Grid container className={classes.mainView}>
                        {showMasterView &&
                            CategoryList()
                        }
                        {showDetailView && categories.length > 0 &&
                            ItemList()
                        }
                    </Grid>
                }
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MenuImageAlbum restaurantId={props.restaurantId}/>
            </TabPanel>
        </div>
    );
}

export default connect(null, appActions)(MenuCardPage);