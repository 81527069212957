import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { connect, useSelector } from "react-redux";
import { appActions } from '../state/actions/app';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    circularProgress: {
        margin: 'auto',
        marginRight: 'auto',
        marginTop: '250px',
    },
    emptyMessage: {
        justifyContent: 'center',
        alignItems: 'center',
        justifyItems: 'center'
    }
}));

function History(props) {
    const classes = useStyles();
    const recentlyViewed = useSelector(state => state.app.recentlyViewed);
    const isLoading = useSelector(state => state.app.isHistoryLoading);

    useEffect(() => {
        // Your code here
        props.getRecentlyViewed();
    }, [props]);

    if (isLoading) {
        return (
            <Grid container spacing={2} elevation={5}>
                <CircularProgress className={classes.circularProgress} />
            </Grid>
        );
    }

    if (!isLoading && recentlyViewed.length === 0 && props.showEmptyMessage) {
        return (
            <Typography className={classes.emptyMessage} variant="h6" align="center">
                No History available
            </Typography>
        );
    }

    return (
        <List className={classes.root}>
            {recentlyViewed.map((viewedItem, index) => (
                <React.Fragment>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar alt={viewedItem.restaurantName} src={viewedItem.thumbnail} variant='rounded'/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={viewedItem.restaurantName}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {viewedItem.street}, {viewedItem.city}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                        <a target="_blank"
                            href={'https://chego.app/menucards/' + viewedItem.city.replace(/\s/g, '') + '/' + viewedItem.restaurantName.replace(/\s/g, '') + '/' + viewedItem.restaurantId}>
                            <Button size="small" color="primary"
                            >
                                View on Chego
                            </Button>
                        </a>
                        <Button size="small" color="primary"
                            component={Link} to={'/menucards/' + viewedItem.restaurantId}
                        >
                            Edit
                        </Button>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </React.Fragment>
            ))}
        </List>
    );
}

export default connect(null, appActions)(History);
