/** @format */

import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import StorefrontIcon from "@material-ui/icons/Storefront";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import lightBlue from "@material-ui/core/colors/lightBlue";
import orange from "@material-ui/core/colors/orange";
import green from "@material-ui/core/colors/green";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "firebase/app";
import "firebase/auth";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"© "}
			<Link color="inherit" href="https://material-ui.com/">
				Chego
			</Link>{" "}
			{new Date().getFullYear()}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100vh",
	},
	appName: {
		marginTop: theme.spacing(1)
	},
	image: {
		backgroundImage: "url(https://source.unsplash.com/random?restaurant)",
		backgroundRepeat: "no-repeat",
		backgroundColor:
			theme.palette.type === "light"
				? theme.palette.grey[50]
				: theme.palette.grey[900],
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	paper: {
		margin: theme.spacing(4, 4),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	featureList: {
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(3),
			marginLeft: theme.spacing(6),
			marginRight: theme.spacing(3),
			marginBottom: theme.spacing(3),
			alignItems: "center",
		},
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1),
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1),
			alignItems: "left"
		}
	},
	placeAvatar: {
		margin: theme.spacing(1),
		marginRight: theme.spacing(3),
		backgroundColor: lightBlue[500],
	},
	menucardAvatar: {
		margin: theme.spacing(1),
		marginRight: theme.spacing(3),
		backgroundColor: orange[500],
	},
	qrcodeAvatar: {
		margin: theme.spacing(1),
		marginRight: theme.spacing(3),
		backgroundColor: green[500],
	},
}));

export default function SignInPage() {
	const classes = useStyles();

	const onSignInClicked = () => {
      const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithRedirect(googleAuthProvider);
  }

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<Grid key="icon" item>
						<img
							width="64"
							height="64"
							alt="Chego logo containing a menu card and a fork."
							src="https://storage.googleapis.com/chego-manager.appspot.com/ChegoManagerIcon.png"
						/>
					</Grid>
					<Typography className={classes.appName} component="h1" variant="h4">
						Chego Manager
					</Typography>
					<List className={classes.featureList}>
						<ListItem alignItems="flex-start">
							<ListItemAvatar>
								<Avatar className={classes.placeAvatar}>
									<StorefrontIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Manage Places"
								secondary="Keep your place details up to date on Chego. Add profile photo, contact info and more."
							/>
						</ListItem>
						<ListItem alignItems="flex-start">
							<ListItemAvatar>
								<Avatar className={classes.menucardAvatar}>
									<RestaurantMenuIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Upload Menu"
								secondary="Add searchable menu cards with rich details like categories and food items with prices and description. 
									"
							/>
						</ListItem>
						<ListItem alignItems="flex-start">
							<ListItemAvatar>
								<Avatar className={classes.qrcodeAvatar}>
									<CameraAltIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="QR Codes"
								secondary="Print QR codes so customers can easily browse and share their favorite food items at your place with friends and family."
							/>
						</ListItem>
					</List>
					<img
						width="200"
						height="48"
						alt="Sign in with Google"
						src="https://firebasestorage.googleapis.com/v0/b/dev-qa-builds.appspot.com/o/dev-qa-builds%2Fsign_in_dark.png?alt=media&token=2e8f8206-1371-4c3a-85dd-f30b19f0d83f"
						onClick={onSignInClicked}
					/>
					<Box mt={5}>
						<Button color="primary" href="/overview">
							Learn More
						</Button>
					</Box>
					<Box mt={5}>
						<Copyright />
					</Box>
				</div>
			</Grid>
		</Grid>
	);
}
