import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MaterialTable from 'material-table';
const getCountry = require("country-currency-map").getCountry;
const getCurrency = require("country-currency-map").getCurrency;

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        background: lightBlue[700]
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    toolbar: theme.mixins.toolbar,
    formGrid: {
        marginLeft: 25,
        marginRight: 25,
        alignItems: 'center',
    },
    form: {
        marginBottom: 20,
        alignItems: 'center',
    },
}));

export default function FoodItemDialog(props) {
    const classes = useStyles();
    // Some older entities in the database have USA as country name.
    let country = props.restaurant.address.country;
    if (country === 'USA') {
        country = 'United States';
    }
    let itemObj = props.item || {
        name: '',
        description: '',
        price: 0.0,
        ingredients: [],
        position: props.position,
        section: props.section,
        location: props.restaurant.location,
        geohash_value: props.restaurant.geohash_value,
        geohash_approx: props.restaurant.geohash_approx,
        restaurant: props.restaurant.id,
        restaurant_name: props.restaurant.name,
        currency: getCountry(country).currency,
    };
    const [state, setState] = React.useState({
        data: itemObj.ingredients.map(ingredient => (
            {
                "name": ingredient
            }
        ))
    });
    const formik = useFormik({
        initialValues: itemObj,
        onSubmit: values => {
            const ingredientList = state.data.map(ingredient => {
                return ingredient.name;
            });
            values.currency = values.currency.toUpperCase();
            values.ingredients = ingredientList;
            if (props.item) {
                props.onItemEdit(values);
            } else {
                props.onItemCreate(values);
            }
        },
    });

    const validateForm = () => {
        const values = formik.values;
        if (!values.name || !values.price || !state.data || state.data.length === 0) {
            return false;
        }
        return true;
    }

    const renderAppBar = () => {
        const title = props.item ? formik.values.name : "Add Food Item";
        const isFormValid = validateForm();
        return (
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <Button disabled={!isFormValid} autoFocus color="inherit" onClick={formik.handleSubmit}>
                        done
                    </Button>
                </Toolbar>
            </AppBar>
        );
    };

    const currencySymbol = getCurrency(itemObj.currency.toUpperCase()).symbolFormat.split('{')[0] || '$';
    return (
        <React.Fragment>
            {renderAppBar()}
            <div className={classes.toolbar}/>
            <Grid container className={classes.mainView} spacing={2} elevation={5}>
                <Grid className={classes.formGrid} item xs={12} sm={6} md={6} elevation={2} square>
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <TextField variant="outlined" margin="normal" required fullWidth
                            id="name" label="Name" name="name" autoComplete="name"
                            onChange={formik.handleChange} value={formik.values.name} />
                        <TextField variant="outlined" margin="normal" required fullWidth multiline
                            rows={4} id="description" label="Description" name="description" autoComplete="description"
                            onChange={formik.handleChange} value={formik.values.description} />
                        <TextField variant="outlined" margin="normal" required fullWidth
                            type="number"
                            id="price" label="Price" name="price" autoComplete="description"
                            onChange={formik.handleChange} value={formik.values.price}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                inputProps: { min: 0}
                            }}
                        />
                    </form>
                    <MaterialTable
                        title="Ingredients"
                        columns={[{ title: 'Name', field: 'name' }]}
                        className={classes.table}
                        data={state.data}
                        options={{
                            filtering: false,
                            exportButton: false,
                            search: false,
                            sorting: false,
                            paging: false,
                            actionsColumnIndex: 2
                        }}
                        searchable={false}
                        filtering={false}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve) => {
                                    resolve();
                                    setState((prevState) => {
                                        const data = [...prevState.data];
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    resolve();
                                    if (oldData) {
                                        setState((prevState) => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            return { ...prevState, data };
                                        });
                                    }
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    resolve();
                                    setState((prevState) => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        return { ...prevState, data };
                                    });
                                }),
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
