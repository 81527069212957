import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { connect } from "react-redux";
import { appActions } from '../state/actions/app';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';
import DevicesIcon from '@material-ui/icons/Devices';
import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import PeopleIcon from '@material-ui/icons/People';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import orange from '@material-ui/core/colors/orange';
import grey from '@material-ui/core/colors/grey';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';
import "firebase/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appIcon: {
        marginRight: theme.spacing(1),
    },
    appBar: {
        position: 'sticky',
        background: lightBlue[700]
    },
    title: {
        flexGrow: 1,
    },
    header: {
        marginTop: theme.spacing(1),
    },
    featureHeader: {
        marginTop: theme.spacing(5),
    },
    featureDescription: {
        marginBottom: theme.spacing(5),
    },
    heroImage: {
        [theme.breakpoints.only('xs')]: {
            width: 360,
            height: 240
        },
        [theme.breakpoints.up('sm')]: {
            width: 640,
            height: 480
        },
        [theme.breakpoints.up('md')]: {
            width: 1040,
            height: 640
        }
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(5)
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    cardGrid: {
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 15
    },
    redBackground: {
        backgroundColor: 'orange',
    },
    cardMedia: {
        height: '290px',
        width: '250px',
        align: 'center',
        justifyContent: 'center'
    },
    cardContent: {
        flexGrow: 1,
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© Chego'}
            {' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

function Overview(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Link to='/'>
                        <Grid key="icon" className={classes.appIcon} item>
                            <img width="32" height="32" alt="Chego logo" src="https://storage.googleapis.com/chego-manager.appspot.com/ChegoManagerIcon.png" />
                        </Grid>
                    </Link>
                    <Typography variant="h6" className={classes.title}>
                        Chego Manager
                    </Typography>
                    <Button color="inherit" href="/">Home</Button>
                </Toolbar>
            </AppBar>
            <main>
                 {/* Hero unit */}
                 <div className={classes.heroContent}>
                    <Container maxWidth="sm">
                        <Grid container justify="center">
                            <img
                                width="64"
                                height="64"
                                alt="Chego logo containing a menu card and a fork."
                                src="https://storage.googleapis.com/chego-manager.appspot.com/ChegoManagerIcon.png"
                            />
                        </Grid>
                        <Typography className={classes.header} variant="h4" align="center" color="textPrimary">
                            Chego Manager
                        </Typography>
                        <Typography variant="h6" align="center" color="textSecondary" paragraph>
                            Manage restaurant menu cards
                        </Typography>
                        <Grid container spacing={2} justify="center">
                            <img className={classes.heroImage} src="https://storage.googleapis.com/chego-manager.appspot.com/ChegoManagerWeb.png" />
                        </Grid>
                     </Container>
                </div>
                <Typography className={classes.featureHeader} variant="h4" align="center" color="textPrimary">
                    Features
                </Typography>
                <Typography className={classes.featureDescription} variant="h6" align="center" color="textSecondary" paragraph>
                    Designed to help your business
                </Typography>
                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={5}>
                        <Grid item key='add-menu-cards' xs={12} sm={6} md={6}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <MoneyOffIcon style={{ color: green[500], fontSize: 50 }} />
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Costs nothing
                                    </Typography>
                                    <Typography>
                                        Chego Manager is free for all. You can upload, make changes to your menu card and more for free.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key='add-menu-cards' xs={12} sm={6} md={6}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <SearchIcon style={{ color: orange[500], fontSize: 50 }} />
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Searchable menu cards
                                    </Typography>
                                    <Typography>
                                        Customers who are visiting your place for the first time may discover their next favorite 
                                        simply by searching ingredients they are familiar with.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key='food-item-rating' xs={12} sm={6} md={6}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <FavoriteIcon style={{ color: red[500], fontSize: 50 }} />
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Ratings for food items
                                    </Typography>
                                    <Typography>
                                        Check what food items customers love and don't like on your menu card. Design or restructure your menu if something is not working.
                                        Introduce new dishes based on successful items on your menu. Repeat.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key='qr-codes' xs={12} sm={6} md={6}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <PeopleIcon style={{ color: blue[500], fontSize: 50 }} />
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Links for food items
                                    </Typography>
                                    <Typography>
                                        Each food item on Chego has a unique link so your customers can share them with their friends and family over Messages, WhatsApp and more.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key='chego-manager-mobile' xs={12} sm={6} md={6}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <DevicesIcon style={{ color: blueGrey[500], fontSize: 50 }} />
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Designed for all devices
                                    </Typography>
                                    <Typography>
                                        Chego Manager makes it easy to change your menu card from any device. The changes are visible immediately on the Chego app.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key='qr-codes' xs={12} sm={6} md={6}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <PrintIcon style={{ fontSize: 50 }} />
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Print QR codes 
                                    </Typography>
                                    <Typography>
                                        The best way for new customers to access your menu card is by scanning QR codes. 
                                        Chego Manager lets you print QR codes in two sizes: Table and Large. Table size is perfect for pasting on each table.
                                        The Large size is ideal for displaying near the entrance or in other places to encourage customers to rate food items.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key='qr-codes' xs={12} sm={6} md={6}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <DescriptionIcon style={{ color: grey[500], fontSize: 50 }} />
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Upload Menu Photos
                                    </Typography>
                                    <Typography>
                                        Chego also supports scanned menu card photos. You can upload multiple high quality photos.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key='qr-codes' xs={12} sm={6} md={6}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <WhatsAppIcon style={{ color: teal[500], fontSize: 50 }} />
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Message us
                                    </Typography>
                                    <Typography>
                                        Send us menu cards or changes you would like to make and we will update your menu card quickly.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <footer className={classes.footer}>
                <Copyright />
            </footer>
            {/* End footer */}
        </div>
    );
}

export default connect(null, appActions)(Overview);
