/** @format */

import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CssBaseline from "@material-ui/core/CssBaseline";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect, useSelector } from "react-redux";
import { appActions } from "../state/actions/app";
import { DropzoneDialog } from "material-ui-dropzone";
import * as imageConversion from 'image-conversion';
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import fire from "../firebase";
import "firebase/auth";

const useStyles = makeStyles((theme) => ({
	heroContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6),
		marginBottom: theme.spacing(20)
	},
	heroButtons: {
		marginTop: theme.spacing(4),
	},
	uploadButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	circularProgress: {
		margin: 'auto',
		marginRight: 'auto',
		marginTop: '250px',
	},
	cardGrid: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(8),
	},
	card: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	cardMedia: {
		paddingTop: "56.25%", // 16:9
	},
	cardContent: {
		flexGrow: 1,
	},
	thumbnail: {
		borderRadius: 10,
		width: 320,
		height: 320,
	},
	primaryButton: {
		backgroundColor: lightBlue[500],
		"&:hover": {
			backgroundColor: lightBlue[700],
		},
		color: "white",
	},
	progressBar: {
		width: 400,
	},
}));

function MenuImageAlbum(props) {
	const classes = useStyles();
	const [openFileDialog, setOpenFileDialog] = useState(false);
	const [openUploadDialog, setOpenUploadDialog] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [deleteItemIndex, setDeleteItemIndex] = React.useState(-1);
	const [filesProgress, setFilesProgress] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	const cards = useSelector((state) => state.app.menuImages);
	const restaurant = useSelector((state) => state.app.restaurantDetails);
	const isLoading = useSelector((state) => state.app.isMenuAlbumLoading);
	const [uploadError, setUploadError] = useState(null);
	const idToken = useSelector((state) => state.app.idToken);

	useEffect(() => {
		// Your code here
		if (props.restaurantId) {
			props.getMenuImages(props.restaurantId, idToken);
		}
	}, [idToken, props]);

	const handleClose = () => {
		setOpenFileDialog(false);
    }

    const handleFilesSubmit = (files) => {
		// Initialize array & set percentage as zero
		const fileCount = files.length;
		let progressList = [];
		for (let i = 0; i < fileCount; i++) {
			progressList.push(0);
		}
		setFilesProgress([].concat(progressList));
		startUpload(files);
		setIsUploading(true);
		setOpenFileDialog(false);
		setOpenUploadDialog(true);
	};
	
	const handleDone = () => {
		setOpenUploadDialog(false);
	}

	const handleOpen = () => {
		setOpenFileDialog(true);
	}

	const handleDeleteDialogOpen = (index) => {
		setOpenDeleteDialog(true);
		setDeleteItemIndex(index);
	};

	const handleDeleteDialogClose = () => {
		setOpenDeleteDialog(false);
	};

	const handleDeleteItem = () => {
		// Upload File
		const image = cards[deleteItemIndex];
		const storageRef = fire.storage("chego-menu-card-images").ref();
		const fullPath = image.name;
		const fileType = image.image_url.split(fullPath)[1].split('?')[0];
		const imageRef = storageRef.child(fullPath+fileType);
		const thumbnailRef = storageRef.child(fullPath + '_thumbnail' + fileType);
		// Delete thumbnail
		thumbnailRef
			.delete().then(function () {
				// Delete the file
				imageRef
					.delete()
					.then(function () {
						// File deleted successfully so update datastore
						props.deleteMenuImage(cards, deleteItemIndex, idToken);
						setDeleteItemIndex(-1);
						setOpenDeleteDialog(false);
					})
					.catch(function (error) {
						// Uh-oh, an error occurred!
					});
			});
	};

	const startUpload = (files) => {
		// Send data to firebase and then navigate to the detail page
		const imagesCount = cards.length;
		const currentDate = moment().format("DDMMMYYYY"); // 26Nov2020

		//Saving files to state for further use and closing Modal.
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const fileType = file.type.split("image/")[1];
			const suffix = imagesCount + i;
			const name = restaurant.id + "_" + currentDate + "_" + suffix;
			const fullPath = name + "." + fileType;
			// Upload File
			const storageRef = fire.storage("chego-menu-card-images").ref();
			const imageRef = storageRef.child(fullPath);
			const uploadTask = imageRef.put(file);
			// Thumbnail
			const thumbnailFullPath = name + "_thumbnail." + fileType;
			imageConversion.compressAccurately(file, {
				size: 100,
				quality: 0.8,
				width: 240,
				height: 400,
			}).then(compressedFile => {
				// Upload Thumbnail
				const thumbnailImageRef = storageRef.child(thumbnailFullPath);
				thumbnailImageRef.put(compressedFile);
			});

			uploadTask.on(
				"state_changed",
				function (snapshot) {
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					let uploadProgressList = filesProgress;
					uploadProgressList[i] = Math.floor(progress);
					setFilesProgress([].concat(uploadProgressList));
				},
				function (error) {
					// Handle unsuccessful uploads
					setUploadError(error);
				},
				function () {
					// Handle successful uploads on complete
					// Update list
					uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
						// Set downloaded url and insert into database
						const imageDetails = {
							image_url: downloadURL,
							thumbnail_url: 'https://storage.googleapis.com/chego-menu-card-images/' + thumbnailFullPath,
							restaurant: restaurant.id,
							position: suffix,
							name: name
						};
						props.updateMenuImages(imageDetails, cards, idToken);
						setIsUploading(false);
					});
				}
			);
		}
	};

	const renderDeleteDialog = () => {
		let dialogTitle = "Delete";
		if (deleteItemIndex > -1) {
			const item = cards[deleteItemIndex];
			const position = item.position + 1;
			dialogTitle = "Delete Page " + position + "?";
		}
		return (
			<Dialog
				open={openDeleteDialog}
				onClose={handleDeleteDialogClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this photo?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteDialogClose} color="primary" autoFocus>
						CANCEL
					</Button>
					<Button onClick={handleDeleteItem} color="secondary" autoFocus>
						DELETE
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const renderDialog = () => {
		return (
			<React.Fragment>
				<DropzoneDialog
					open={openFileDialog}
					onSave={handleFilesSubmit}
					dropzoneText=" "
					dialogTitle="Upload Menu"
					fullWidth={true}
					maxFileSize={10000000}
					acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
					showPreviews={true}
					filesLimit={10}
					onClose={handleClose}
				/>
				<Dialog
					ropen={openUploadDialog}
					onClose={handleDone}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Use Google's location service?"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Let Google help apps determine location. This means sending
							anonymous location data to Google, even when no apps are running.
						</DialogContentText>
					</DialogContent>
					{!isUploading && (
						<DialogActions>
							<Button onClick={handleDone} color="primary" autoFocus>
								OK
							</Button>
						</DialogActions>
					)}
				</Dialog>
			</React.Fragment>
		);
	}
	
	const renderCards = () => {
		return (
			<Container className={classes.cardGrid} maxWidth="md">
				{/* End hero unit */}
				<div className={classes.uploadButton}>
					<Grid container spacing={2} justify="center">
						<Grid item>
							<Button
								variant="contained"
								className={classes.primaryButton}
								onClick={handleOpen}
							>
								Upload Menu
							</Button>
						</Grid>
					</Grid>
				</div>
				<Grid container spacing={4}>
					{cards.map((card, index) => (
						<Grid item key={card.name} xs={12} sm={6} md={4}>
							<Card className={classes.card}>
								<CardMedia
									className={classes.cardMedia}
									image={card.thumbnail_url}
								/>
								<CardActions>
									<Button
										size="small"
										color="primary"
										onClick={(event) => handleDeleteDialogOpen(index)}
									>
										Delete
									</Button>
								</CardActions>
							</Card>
						</Grid>
					))}
				</Grid>
			</Container>
		);
	}

	const renderUploadingDialog = () => {
		let progressBars = [];
		for (const fileProgress of filesProgress) {
			progressBars.push(
				<React.Fragment>
					<Box display="flex" alignItems="center">
						<Box width="100%" mr={1}>
							<LinearProgress
								variant="determinate"
								value={fileProgress}
							/>
						</Box>
						<Box minWidth={35}>
							<Typography
								variant="body2"
								color="textSecondary"
							>{`${ Math.round(fileProgress) }%`}</Typography>
						</Box>
					</Box>
					<DialogContentText id="alert-dialog-description">
						You can set my maximum width and whether to adapt or not. You
						can set my maximum width and whether to adapt or not.
					</DialogContentText>
				</React.Fragment>
			);
		}

		return (
			<Dialog
				open={openUploadDialog}
				onClose={handleDone}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<DialogTitle id="alert-dialog-title">Uploading</DialogTitle>
				<DialogContent classNames={classes.progressBar}>
					{!uploadError && (
						<React.Fragment>
							{progressBars}
						</React.Fragment>
					)}
					{uploadError && (
						<DialogContentText id="alert-dialog-description">
							Error
						</DialogContentText>
					)}
				</DialogContent>
			</Dialog>
		);
	};

	const renderLoader = () => {
		return (
			<Grid container spacing={2} elevation={5}>
				<CircularProgress className={classes.circularProgress} />
			</Grid>
		);
	}

	const NoImagesPlaceHolder = () => {
		return (
			<div className={classes.heroContent}>
				<Container maxWidth="sm">
					<Grid container spacing={2} justify="center">
						<img
							className={classes.thumbnail}
							alt="Hamburger Illustration"
							src="https://storage.googleapis.com/chego-web.appspot.com/hamburger.svg"
						/>
					</Grid>
					<div className={classes.heroButtons}>
						<Grid container spacing={2} justify="center">
							<Grid item>
								<Button
									variant="contained"
									className={classes.primaryButton}
									onClick={handleOpen}
								>
									Upload Menu
								</Button>
							</Grid>
						</Grid>
					</div>
				</Container>
			</div>
		);
	}

	if (isLoading) {
		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					{renderLoader()}
				</main>
			</React.Fragment>
		);
	}
	
	return (
		<React.Fragment>
			<CssBaseline />
			<main>
				{cards.length === 0 && NoImagesPlaceHolder()}
				{cards.length > 0 && renderCards()}
				{renderDialog()}
				{renderUploadingDialog()}
				{renderDeleteDialog()}
			</main>
		</React.Fragment>
	);
}

export default connect(null, appActions)(MenuImageAlbum);