import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MaterialTable from 'material-table';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        background: lightBlue[700]
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    toolbar: theme.mixins.toolbar,
    formGrid: {
        marginLeft: 25,
        marginRight: 25,
        marginBottom: 75,
        alignItems: 'center',
    }
}));

export default function EditContactDialog(props) {
    const classes = useStyles();
    const [phones, setPhones] = React.useState({
        columns: [
            {
                title: 'Type',
                field: 'phone_type',
                lookup: { "regular": 'Regular', "orders": 'Orders', "other": "Other" },
            },
            { title: 'Number', field: 'number' }
        ],
        data: props.restaurant.phones || [],
    });

    const handleSubmit = () => {
        props.onContactDataChanged(phones.data);
    };

    const handleFormValidation = () => {
        let isNotValid = false;
        if (phones.length > 0) {
            return true;
        }
        return isNotValid;
    };

    return (
        <React.Fragment>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Contact Details
                    </Typography>
                    <Button autoFocus disabled={handleFormValidation()} color="inherit" onClick={handleSubmit}>
                        done
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classes.toolbar} />
            <Grid container className={classes.mainView} spacing={2} elevation={5}>
                <Grid className={classes.formGrid} item xs={12} sm={6} md={6} elevation={2} square>
                    <MaterialTable
                        title="Phone"
                        columns={phones.columns}
                        data={phones.data}
                        options={{
                            filtering: false,
                            exportButton: false,
                            search: false,
                            sorting: false,
                            paging: false,
                            actionsColumnIndex: 2
                        }}
                        searchable={false}
                        filtering={false}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve) => {
                                    resolve();
                                    setPhones((prevState) => {
                                        const data = [...prevState.data];
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    resolve();
                                    if (oldData) {
                                        setPhones((prevState) => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            return { ...prevState, data };
                                        });
                                    }
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    resolve();
                                    setPhones((prevState) => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        return { ...prevState, data };
                                    });
                                }),
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
