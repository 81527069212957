import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import QRCode from "qrcode.react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export default class ScanCodeSmall extends React.Component {
    constructor(props) {
        super(props);
        this.classes = makeStyles({
            card: {
                display: "flex",
                width: 64,
                height: 86,
            },
            details: {
                display: "flex",
                flexDirection: "column",
            },
            content: {
                flex: "1 0 auto",
            },
            qrCode: {
                width: 151,
                margin: 10
            },
            qrItem: {
                marginLeft: 50
            },
            gridColumn: {
                marginTop: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
        });
    }
    render() {
        const link = this.props.link;
        let count = this.props.count || 1;
        let qrcodes = [];
        while (count > 0) {
            qrcodes.push(
                <Grid item key={count} justifyContent="center">
                    <Card className={this.classes.card}>
                        <div className={this.classes.details}>
                            <CardContent className={this.classes.content}>
                                <Typography variant="body1" color="textSecondary">
                                    Scan to view in Chego
                                </Typography>
                            </CardContent>
                            <div className={this.classes.controls}></div>
                        </div>
                        <CardMedia
                            className={this.classes.qrCode}
                        >
                            <QRCode
                                level="H"
                                size={192}
                                value={link}
                                imageSettings={{
                                    excavate: true,
                                    height: 48,
                                    width: 48,
                                    src:
                                        "https://storage.googleapis.com/palate-1301.appspot.com/ChegoIcon.png",
                                }}
                            />
                        </CardMedia>
                    </Card>
                </Grid>
            );
            count--;
        }
        return (
            <React.Fragment>
                <Grid container spacing={2} className={this.props.qrView}>
                    {qrcodes}
                </Grid>
            </React.Fragment>
        );
    }
}