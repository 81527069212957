import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { connect, useSelector } from "react-redux";
import { appActions } from '../state/actions/app';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom'
import "firebase/auth";
import Restaurant from '../models/Restaurant';
import firebase from "firebase/app";
import History from './History';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: 500,
    },
    appIcon: {
        marginRight: theme.spacing(1),
    },
    appBar: {
        position: 'sticky',
        background: lightBlue[700]
    },
    title: {
        flexGrow: 1,
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(5)
    },
    primaryButton: {
        backgroundColor: lightBlue[500],
        '&:hover': {
            backgroundColor: lightBlue[700],
        },
        marginTop: 40,
        marginBottom: 30,
        alignItems: 'bottom',
        color: 'white'
    },
    cardGrid: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    urlFieldRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© Chego'}
            {' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const handleLogOut = () => {
    firebase.app().auth().signOut();
}



function HomePage(props) {
    const classes = useStyles();
    const currentUser = firebase.app().auth().currentUser;
    const recentlyViewed = useSelector(state => state.app.recentlyViewed);
    const isLoading = useSelector(state => state.app.isHistoryLoading);
    const [pastedUrl, setUrl] = useState('');

    useEffect(() => {
        // Your code here
        let newRestaurant = new Restaurant();
        newRestaurant.name = '';
        newRestaurant.modifiedTime = new Date();
        newRestaurant.id = null;
        props.setRestaurantDetails(newRestaurant);
        props.setDetailLoading(true);
    }, [props]);

    const onUrlChange = (event) => {
        setUrl(event.target.value)
    };

    const onUrlSubmit = () => {
        if (pastedUrl && pastedUrl.indexOf('https://chego.app/menucards/') === 0) {
            const ids = pastedUrl.split('/').pop();
            const restaurantId = ids.split('_')[0];
            const editUrl = `/menucards/${restaurantId}`;
            props.history.push(editUrl);
        }
    };

    const URLField = () => {
        return (
            <Paper component="form" className={classes.urlFieldRoot}>
                <InputBase
                    id="url"
                    name="url"
                    className={classes.input}
                    placeholder="Paste menu card's link"
                    onChange={onUrlChange}
                    value={pastedUrl}
                    inputProps={{ 'aria-label': 'Paste menu card url' }}
                />
                <IconButton className={classes.iconButton} aria-label="open edit page"
                    onClick={onUrlSubmit} disabled={!pastedUrl}
                >
                    <LaunchIcon color='primary'/>
                </IconButton>
            </Paper>
        );
    }

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Grid key="icon" className={classes.appIcon} item>
                        <img width="32" height="32" alt="Chego logo" src="https://storage.googleapis.com/chego-manager.appspot.com/ChegoManagerIcon.png" />
                    </Grid>
                    <Typography variant="h6" className={classes.title}>
                        Chego Manager
                    </Typography>
                    <Button color="inherit" onClick={handleLogOut}>Sign Out</Button>
                </Toolbar>
            </AppBar>
            <main>
                 {/* Hero unit */}
                 <div className={classes.heroContent}>
                     <Container maxWidth="sm">
                        <Typography component="h1" variant="h5" align="center" color="textPrimary" gutterBottom>
                            Welcome {currentUser.displayName || ''}
                        </Typography>
                        <Typography variant="h6" align="center" color="textSecondary" paragraph>
                            Find your restaurant on Chego or add.
                        </Typography>
                        <Grid container className={classes.mainView} spacing={2} elevation={5}>
                            <Grid className={classes.formGrid} item xs={12} sm={12} md={12} elevation={2} square>
                                <URLField/>
                            </Grid>
                        </Grid>
                        <div>
                             <Grid container spacing={1} justify="center">
                                <Grid item>
                                    <Button className={classes.primaryButton} variant="contained" color="primary"
                                        component={Link} to="/new-menu-card/"
                                    >
                                        Add New Place
                                    </Button>
                                </Grid>
                             </Grid>
                         </div>
                     </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    {recentlyViewed.length > 0 &&
                        <Typography variant="h6" align="center" color="textSecondary" paragraph>
                            Recently Modified
                        </Typography>
                    }
                    {recentlyViewed.length === 0 && !isLoading &&
                        <Typography variant="h6" align="center" color="textSecondary" paragraph>
                            You have not modified any menu cards yet.
                        </Typography>
                    }
                    <Grid container spacing={4}>
                        <History/>
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <footer className={classes.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                    
                </Typography>
                    <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                </Typography>
            <Copyright />
            </footer>
            {/* End footer */}
        </div>
    );
}

export default connect(null, appActions)(HomePage);
