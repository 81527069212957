import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import QRCode from "qrcode.react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from '@material-ui/icons/Favorite';
import red from '@material-ui/core/colors/red';

import Grid from "@material-ui/core/Grid";

export default class ScanCodeLarge extends React.Component {
    constructor(props) {
        super(props);
        this.classes = makeStyles({
            root: {
                display: "flex",
                width: 500,
                height: 600,
            },
            details: {
                display: "flex",
                flexDirection: "column",
            },
            content: {
                flex: "1 0 auto",
            },
            qrCode: {
                width: 151,
                margin: 10
            },
            gridColumn: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 300
            },
        });
    }
    render() {
        const link = this.props.link;
        return (
            <React.Fragment>
                <Grid container spacing={10} className={this.props.qrView}>
                    <Grid item key="largeGrid" className={this.classes.gridColumn}>
                        <Card className={this.classes.root}>
                            <CardActionArea>
                                <div className={this.classes.details}>
                                    <CardContent className={this.classes.content}>
                                        <FavoriteIcon style={{ color: red[500], fontSize: 80 }} />
                                        <Typography variant="h3" component="h3">
                                            Rate our food on Chego
                                    </Typography>
                                        <Typography variant="h6" color="textSecondary" component="h6">
                                            Open Camera app and scan to view menu card.
                                    </Typography>
                                        <Typography variant="h6" color="textSecondary" component="h6">
                                            More info at www.chego.app
                                    </Typography>
                                    </CardContent>
                                    <div className={this.classes.controls}></div>
                                </div>
                                <CardMedia
                                    className={this.classes.qrCode}
                                >
                                    <QRCode
                                        level="H"
                                        size={550}
                                        value={link}
                                        imageSettings={{
                                            excavate: true,
                                            height: 128,
                                            width: 128,
                                            src:
                                                "https://storage.googleapis.com/palate-1301.appspot.com/ChegoIcon.png",
                                        }}
                                    />
                                </CardMedia>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}