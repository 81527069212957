import {ACTIONS} from '../actions/app';
import Restaurant from '../../models/Restaurant';
import cuisineData from "../../static/cuisine-data";

const prepareCuisineData = () => {
    let cuisines = [];
    let cuisineMap = {};
    for (const cuisineItem of cuisineData) {
        const value = cuisineItem.toLowerCase().replace(/\s/g, '');
        cuisines.push({
            value: value,
            text: cuisineItem
        });
        cuisineMap[value] = cuisineItem;
    }
    return {cuisines, cuisineMap};
}

const data = prepareCuisineData();

const initialState = {
    restaurantDetails: new Restaurant(),
    cuisineData: data.cuisines,
    cuisineMap: data.cuisineMap,
    isDetailLoading: true,
    isAuthenticated: false,
    restaurantUpdate: '',
    isMenuLoading: true,
    isMenuAlbumLoading: true,
    isHistoryLoading: true,
    detailedMenu: [],
    nextPageToken: null,
    menuData: null,
    menuImages: [],
    restaurantId: null,
    foodItemId: null,
    pageNotFound: false,
    idToken: null,
    recentlyViewed: []
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTIONS.RESTAURANT_DETAILS:
            return Object.assign({}, state, {
                restaurantDetails: action.restaurantDetails,
                isDetailLoading: false,
                restaurantUpdate: action.requestType
            });
        case ACTIONS.GET_FOOD_ITEMS:
            return Object.assign({}, state, {
                detailedMenu: action.detailedMenu,
                nextPageToken: action.nextPageToken,
                isMenuLoading: false
            });
        case ACTIONS.GET_NEXT_PAGE:
            return Object.assign({}, state, {
                detailedMenu: action.detailedMenu,
                nextPageToken: action.nextPageToken,
                isMenuLoading: false
            });
        case ACTIONS.DETAILED_MENU:
            return Object.assign({}, state, {
                detailedMenu: action.detailedMenu,
                menuData: action.menuData
            });
        case ACTIONS.GET_MENU_IMAGES:
            return Object.assign({}, state, {
                menuImages: [].concat(action.menuImages),
                isMenuAlbumLoading: action.isMenuAlbumLoading
            });
        case ACTIONS.ERROR:
            return Object.assign({}, state, {
                pageNotFound: true
            });
        case ACTIONS.SET_MENUCARD_ID:
            return Object.assign({}, state, {
                restaurantId: action.restaurantId,
                foodItemId: action.foodItemId
            });
        case ACTIONS.SET_RESTAURANT_DETAILS:
            let newData = action.restaurantDetails;
            newData.modifiedTime = new Date();
            return Object.assign({}, state, {
                restaurantDetails: newData,
                isDetailLoading: false,
            });
        case ACTIONS.SAVE_FOOD_ITEMS:
            return Object.assign({}, state, {
                detailedMenu: [].concat(action.detailedMenu),
            });
        case ACTIONS.GET_RECENTLY_VIEWED:
            return Object.assign({}, state, {
                recentlyViewed: [].concat(action.recentlyViewed),
                isHistoryLoading: false,
            });
        case ACTIONS.SET_AUTHENTICATED:
            return Object.assign({}, state, {
                isAuthenticated: action.isAuthenticated,
            });
        case ACTIONS.SET_DETAIL_LOADING:
            return Object.assign({}, state, {
                isDetailLoading: action.isDetailLoading,
            });
        case ACTIONS.SET_MENU_ALBUM_LOADING:
            return Object.assign({}, state, {
                isMenuAlbumLoading: action.isMenuAlbumLoading,
            });
        case ACTIONS.SET_ID_TOKEN:
            return Object.assign({}, state, {
                idToken: action.idToken,
                isAuthenticated: action.isAuthenticated,
            });
            
        default:
            return state;
    }
}
