import React from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import StorefrontIcon from "@material-ui/icons/Storefront";
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import SettingsIcon from '@material-ui/icons/Settings';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from "react-redux";
import { appActions } from './state/actions/app';
import PlaceDetails from "./pages/PlaceDetails";
import ForYou from "./pages/ForYou";
import MenuCardPage from "./pages/MenuCardPage";
import AppSettings from "./pages/AppSettings";
import Grid from '@material-ui/core/Grid';

const drawerWidth = 240;
const bottomBarSmallHeight = 70;
const bottomBarLargeHeight = 86;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    bottomBar: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        [theme.breakpoints.up('sm')]: {
            height: bottomBarSmallHeight,
        },
        [theme.breakpoints.down('sm')]: {
            height: bottomBarLargeHeight,
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(3)
        },
    },
    circularProgress: {
        margin: 'auto',
        marginRight: 'auto',
        marginTop: '250px',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.background.paper,
    },
    content: {
        flexGrow: 1,
    },
    appTitle: {
        marginTop: theme.spacing(3)
    },
    titleText: {
        margin: theme.spacing(-0.4, 0, 3.5, 1),
    },
}));

function AppNavigation(props) {
    const classes = useStyles();
    const [activeIndex, setActiveIndex] = React.useState(0);
    // Get the id from the url provided by react-router
    const restaurantId = props.match.params.id;
    const idToken = useSelector(state => state.app.idToken);
    let labels = ['Place'];
    let icons = [<StorefrontIcon />];
    
    if (!idToken) {
        props.getFirebaseIdToken();
    }

    if (restaurantId) {
        // Show menu card only if the place is already added
        labels.push('Menu Card');
        icons.push(<RestaurantMenuIcon />);
        // Show QR codes only if the place is already added
        labels.push('QR Codes');
        icons.push(<CameraAltIcon />);
    }
    labels.push('Settings');
    icons.push(<SettingsIcon />);

    const drawer = (
        <React.Fragment>
            <div className={classes.appTitle}>
                <Grid container justify="center" spacing={1}>
                    <Link to='/'>
                        <Grid key="icon" item>
                            <img width="32" height="32" alt="Chego logo containing a menu card and a fork."
                                src="https://storage.googleapis.com/chego-manager.appspot.com/ChegoManagerIcon.png" />
                        </Grid>
                    </Link>
                    <Grid key="icon" item>
                        <Typography className={classes.titleText} variant="h6">
                            Chego Manager
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <List>
                {labels.map((text, index) => (
                    <ListItem button selected={activeIndex === index}
                        onClick={event => setActiveIndex(index)}>
                        <ListItemIcon>
                            {icons[index]}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </React.Fragment>
    );

    const bottonNavigation = () => {
        let actions = [<BottomNavigationAction label="Place" icon={<StorefrontIcon />} />];
        if (restaurantId) {
            actions.push(
                <BottomNavigationAction label="Menu Card" icon={<RestaurantMenuIcon />} />
            );
            actions.push(
                <BottomNavigationAction label="QR Codes" icon={<CameraAltIcon />} />
            );
        }
        actions.push(
            <BottomNavigationAction label="Settings" icon={<SettingsIcon />} />
        );

        return (
            <React.Fragment>
                <BottomNavigation value={activeIndex}
                    onChange={(event, newValue) => {
                        setActiveIndex(newValue);
                    }}
                    showLabels
                    className={classes.bottomBar}
                >
                    {actions}
                </BottomNavigation>
            </React.Fragment>
        );
    };

    const pageContent = () => {
        return (
            <div className={classes.content}>
                <div className={classes.toolbar}/>
                {activeIndex === 0 &&
                    <PlaceDetails restaurantId={restaurantId}/>
                }
                {activeIndex === 1 && restaurantId &&
                    <MenuCardPage restaurantId={restaurantId} />
                }
                {activeIndex === 2 && restaurantId &&
                    <ForYou restaurantId={restaurantId} />
                }
                {activeIndex === 3 && restaurantId &&
                    <AppSettings />
                }
                {activeIndex === 1 && !restaurantId &&
                    <AppSettings />
                }
            </div >
        );
    };

    if (!idToken) {
        return (
            <Grid container spacing={2} elevation={5}>
                <CircularProgress className={classes.circularProgress} />
            </Grid>
        );
    }
    
    return (
        <React.Fragment>
            <div className={classes.root}>
                <CssBaseline />
                <Hidden smDown implementation="css">
                    <div className={classes.drawer} aria-label="navigation drawer">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open>
                            {drawer}
                        </Drawer>
                    </div>
                </Hidden>
                {pageContent()}
            </div>
            <Hidden mdUp implementation="css">
                {bottonNavigation()}
            </Hidden>        
        </React.Fragment>
    );
}

AppNavigation.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.any,
};

export default connect(null, appActions)(AppNavigation);