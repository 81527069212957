import axios from 'axios';
import fire from "../../firebase";
import firebase from "firebase/app";
import moment from "moment";
import "firebase/auth";

const ACTIONS = {
    RESTAURANT_DETAILS: 'RESTAURANT_DETAILS',
    DETAILED_MENU: 'DETAILED_MENU',
    SET_MENUCARD_ID: 'SET_MENUCARD_ID',
    GET_FOOD_ITEMS: 'GET_FOOD_ITEMS',
    GET_NEXT_PAGE: 'GET_NEXT_PAGE',
    GET_MENU_IMAGES: 'GET_MENU_IMAGES',
    SAVE_FOOD_ITEMS: 'SAVE_FOOD_ITEMS',
    GET_RECENTLY_VIEWED: 'GET_RECENTLY_VIEWED',
    SET_AUTHENTICATED: 'SET_AUTHENTICATED',
    SET_ID_TOKEN: 'SET_ID_TOKEN',
    SET_DETAIL_LOADING: 'SET_DETAIL_LOADING',
    SET_RESTAURANT_DETAILS: 'SET_RESTAURANT_DETAILS',
    SET_MENU_ALBUM_LOADING: 'SET_MENU_ALBUM_LOADING',
    ERROR: 'ERROR'
};

const serverUrl = process.env.REACT_APP_SERVER_URL;
const chegoWebKey = process.env.REACT_APP_CHEGO_WEB_KEY;
const chegoEditorKey = process.env.REACT_APP_CHEGO_EDITOR_KEY;
const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

const updateRecentlyViewed = (restaurantDetails) => {
    const restaurantId = restaurantDetails.id;
    const currentUser = firebase.app().auth().currentUser;
    const refId = restaurantId + '_' + currentUser.uid;
    // Set downloaded url and insert into database
    const record = {
        editId: refId,
        restaurantId: restaurantId,
        restaurantName: restaurantDetails.name,
        thumbnail: restaurantDetails.thumbnail,
        street: restaurantDetails.address.street,
        city: restaurantDetails.address.city,
        uploadedTime: moment().format(),
        authorId: currentUser.uid,
        authorName: currentUser.displayName
    };
    fire.firestore().collection("recently-viewed").doc(refId).set(record)
        .then(function (docRef) {
            console.log("Successfully received history")
        })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
};

let appActions = function(dispatch) {
    return {
        getRestaurantDetails: (restaurantId, idToken) => {
            const url = serverUrl + "/_ah/api/fitl/v1/restaurant?id="+restaurantId+"&key=" + chegoWebKey;
            axios({
                url: url,
                method: 'get',
                headers: {
                    'X-Auth-Token': idToken
                }
            })
            .then(function (response) {
                dispatch({
                    type: ACTIONS.RESTAURANT_DETAILS,
                    restaurantDetails: response.data
                });
            })
            .catch(function (error) {
                // Stop spinner
                dispatch({
                    type: ACTIONS.ERROR,
                });
                console.log(error);
            });
        },
        getFoodItems: (restaurantId, section, idToken) => {
            const url = serverUrl + "/_ah/api/fitl/v1/menusection?limit=100&restaurant="+
                restaurantId + "&section=" + section + "&key="+ chegoWebKey;
            axios({
                url: url,
                method: 'get',
                headers: {
                    'X-Auth-Token': idToken
                }
            })
            .then(function (response) {
                dispatch({
                    type: ACTIONS.GET_FOOD_ITEMS,
                    detailedMenu: response.data.items,
                    nextPageToken: response.data.nextPageToken
                });
            })
            .catch(function (error) {
                // Stop spinner
                console.log(error);
            });
        },
        getNextPage: (restaurantId, nextPageToken, detailedMenu, idToken) => {
            const url = serverUrl + "/_ah/api/fitl/v1/menusection?limit=100&restaurant="+
                restaurantId + "&key="+ chegoWebKey + "&pageToken=" + nextPageToken;
            axios({
                url: url,
                method: 'get',
                headers: {
                    'X-Auth-Token': idToken
                }
            })
            .then(function (response) {
                var newMenu = detailedMenu.concat(response.data.items);
                dispatch({
                    type: ACTIONS.GET_NEXT_PAGE,
                    detailedMenu: newMenu,
                    nextPageToken: response.data.nextPageToken
                });
            })
            .catch(function (error) {
                // Stop spinner
                console.log(error);
            });
        },
        getMenuImages: (restaurantId, idToken) => {
            const url = serverUrl + "/_ah/api/fitl/v1/menucardimages?restaurant="+
                restaurantId + "&key=" + chegoWebKey;
            axios({
                url: url,
                method: 'get',
                headers: {
                    'X-Auth-Token': idToken
                }
            })
            .then(function (response) {
                dispatch({
                    type: ACTIONS.GET_MENU_IMAGES,
                    menuImages: response.data.items,
                    isMenuAlbumLoading: false
                });
            })
            .catch(function (error) {
                // Stop spinner
                console.log(error);
            });
        },
        setMenuCardId: (restaurantId, foodItemId) => {
            if (restaurantId) {
              dispatch({type: ACTIONS.SET_MENUCARD_ID, restaurantId: restaurantId, foodItemId: foodItemId});
            }
        },
        updateRestaurant: (restaurantDetails, idToken) => {
            const url = serverUrl + "/_ah/api/fitl/v1/restaurant?&key=" + chegoEditorKey;
            let promise;
            let requestType;
            if (restaurantDetails.id) {
                promise = axios({
                    url: url,
                    data: restaurantDetails,
                    method: 'put',
                    headers: {
                        'X-Auth-Token': idToken
                    }
                });
                requestType = 'put';
            } else {
                promise = axios({
                    url: url,
                    data: restaurantDetails,
                    method: 'post',
                    headers: {
                        'X-Auth-Token': idToken
                    }
                });
                requestType = 'post';
            }
            promise.then(function (response) {
                updateRecentlyViewed(restaurantDetails);
                dispatch({
                    type: ACTIONS.SET_RESTAURANT_DETAILS,
                    restaurantDetails: response.data,
                    requestType: requestType
                });
            })
            .catch(function (error) {
                // Stop spinner
                dispatch({
                    type: ACTIONS.ERROR,
                });
                console.log(error);
            });
        },
        updateMenuSections: (restaurantDetails, idToken) => {
            const url = serverUrl + "/_ah/api/fitl/v1/menusections?&key=" + chegoEditorKey;
            if (restaurantDetails.id) {
                const data = {
                    id: restaurantDetails.id,
                    sections: restaurantDetails.sections
                };
                let promise = axios({
                    url: url,
                    data: data,
                    method: 'put',
                    headers: {
                        'X-Auth-Token': idToken
                    }
                });
                promise.then(function (response) {
                    dispatch({
                        type: ACTIONS.RESTAURANT_DETAILS,
                        restaurantDetails: restaurantDetails,
                    });
                })
                .catch(function (error) {
                    // Stop spinner
                    dispatch({
                        type: ACTIONS.ERROR,
                    });
                    console.log(error);
                });
            }
        },
        updateFoodItem: (foodItemDetails, sectionItems, idToken) => {
            const url = serverUrl + "/_ah/api/fitl/v1/fooditem?&key=" + chegoEditorKey;
            let promise;

            if (foodItemDetails.id) {
                promise = axios({
                    url: url,
                    data: foodItemDetails,
                    method: 'put',
                    headers: {
                        'X-Auth-Token': idToken
                    }
                });
            } else {
                promise = axios({
                    url: url,
                    data: foodItemDetails,
                    method: 'post',
                    headers: {
                        'X-Auth-Token': idToken
                    }
                });
            }
            promise.then(function (response) {
                // Dispatch and display updated message
                var allItems = sectionItems;
                if (!foodItemDetails.id) {
                    allItems.push(response.data);
                }
                dispatch({
                    type: ACTIONS.SAVE_FOOD_ITEMS,
                    detailedMenu: allItems,
                });
            })
            .catch(function (error) {
                // Stop spinner
                dispatch({
                    type: ACTIONS.ERROR,
                });
                console.log(error);
            });
        },
        deleteFoodItem: (sectionItems, index, idToken) => {
            const item = sectionItems[index];
            const url = serverUrl + "/_ah/api/fitl/v1/fooditem?&key=" + chegoEditorKey + '&id=' + item.id;
            axios({
                url: url,
                method: 'delete',
                headers: {
                    'X-Auth-Token': idToken
                }
            })
            .then(function (response) {
                // Dispatch and display updated message
                sectionItems.splice(index, 1);
                dispatch({
                    type: ACTIONS.SAVE_FOOD_ITEMS,
                    detailedMenu: sectionItems,
                });
            })
            .catch(function (error) {
                // Stop spinner
                dispatch({
                    type: ACTIONS.ERROR,
                });
                console.log(error);
            });
        },
        setRestaurantData: (restaurantDetails) => {
            dispatch({
                type: ACTIONS.RESTAURANT_DETAILS,
                restaurantDetails: restaurantDetails,
            });
        },
        updateMenuImages: (imageDetails, menuImages, idToken) => {
            const url = serverUrl + "/_ah/api/fitl/v1/menucardimage?key=" + chegoEditorKey;
            axios({
                url: url,
                data: imageDetails,
                method: 'post',
                headers: {
                    'X-Auth-Token': idToken
                }
            })
            .then(function (response) {
                menuImages.push(response.data);
                dispatch({
                    type: ACTIONS.GET_MENU_IMAGES,
                    menuImages: menuImages,
                    isMenuAlbumLoading: false
                });
            })
            .catch(function (error) {
                // Stop spinner
                console.log(error);
            });
        },
        deleteMenuImage: (images, index, idToken) => {
            const image = images[index];
            const url = serverUrl + "/_ah/api/fitl/v1/menucardimage?key=" + chegoEditorKey + '&id=' + image.id;;
            axios({
                url: url,
                method: 'delete',
                headers: {
                    'X-Auth-Token': idToken
                }
            })
            .then(function (response) {
                // Dispatch and display updated message
                images.splice(index, 1);
                dispatch({
                    type: ACTIONS.GET_MENU_IMAGES,
                    menuImages: images,
                });
            })
            .catch(function (error) {
                // Stop spinner
                console.log(error);
            });
        },
        getRecentlyViewed: () => {
            const userId = firebase.app().auth().currentUser.uid;
            // Set downloaded url and insert into database
            // Make network call
            fire.firestore().collection("recently-viewed").where("authorId", "==", userId)
                .get().then((querySnapshot) => {
                    let recentlyViewed = [];
                    querySnapshot.forEach((doc) => {
                        recentlyViewed.push(doc.data());
                    });
                    dispatch({
                        type: ACTIONS.GET_RECENTLY_VIEWED,
                        recentlyViewed: [].concat(recentlyViewed)
                    });
                });
        },
        getFirebaseIdToken: () => {
            firebase.app().auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
                // Send token to your backend via HTTPS
                dispatch({
                    type: ACTIONS.SET_ID_TOKEN,
                    isAuthenticated: true,
                    idToken: idToken
                });
            }).catch(function (error) {
                // Handle error
                dispatch({
                    type: ACTIONS.SET_AUTHENTICATED,
                    isAuthenticated: false
                });
            });
        },
        setDetailLoading: (isDetailLoading) => {
            dispatch({
                type: ACTIONS.SET_DETAIL_LOADING,
                isDetailLoading: isDetailLoading
            });
        },
        setMenuAlbumLoading: (isMenuAlbumLoading) => {
            dispatch({
                type: ACTIONS.SET_MENU_ALBUM_LOADING,
                isMenuAlbumLoading: isMenuAlbumLoading
            });
        },
        setRestaurantDetails: (restaurantDetails) => {
            dispatch({
                type: ACTIONS.SET_RESTAURANT_DETAILS,
                restaurantDetails: restaurantDetails
            });
        }
    }
};
export {appActions, ACTIONS};
