import React, { useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import ScanCodeSmall from '../scan-codes/ScanCodeSmall';
import ScanCodeLarge from '../scan-codes/ScanCodeLarge';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import { useFormik } from 'formik';
import { useReactToPrint } from 'react-to-print';
import { makeStyles } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { connect, useSelector } from "react-redux";
import { appActions } from '../state/actions/app';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    mainView: {
        marginBottom: theme.spacing(15),
    },
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    gridColumn: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${ drawerWidth }px)`,
            marginLeft: drawerWidth,
        },
        background: lightBlue[700]
    },
    qrContainer: {
        padding: theme.spacing(1)
    },
    title: {
        flexGrow: 1,
    },
    formView: {
        marginTop: theme.spacing(5)
    },
    primaryButton: {
        margin: theme.spacing(3, 0, 10),
        backgroundColor: lightBlue[500],
        "&:hover": {
            backgroundColor: lightBlue[700],
        },
        color: "white",
    },
    qrView: {
        marginTop: theme.spacing(1.5),
        justifyContent: "center"
    },
}));

function ForYou(props) {
    const classes = useStyles();
    const restaurant = useSelector(state => state.app.restaurantDetails);
    const componentRef = useRef();
    const restaurantUrl = 'https://chego.app/menucards/' + restaurant.address.city.replace(/\s/g, '') + '/' + restaurant.name.replace(/\s/g, '') + '/' + restaurant.id;

    const formik = useFormik({
        initialValues: {
            count: 6,
            size: 'small'
        },
        onSubmit: useReactToPrint({
            content: () => componentRef.current,
        })
    });

    const minCopies = formik.values.size ? 6 : 1;

    const renderAppBar = () => {
        return (
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography color="white" variant="h6" className={classes.title}>
                        QR Codes
                    </Typography>
                    <IconButton edge="start" color="inherit" aria-label="Open in Chego" target="_blank" href={restaurantUrl}>
                        <LaunchIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        );
    };

    
    return (
        <div className={classes.paper}>
            {renderAppBar()}
            <Grid container className={classes.mainView} spacing={2} elevation={5}>
                <Grid className={classes.gridColumn} item xs={12} sm={5} md={5} elevation={2} square>
                    <Typography variant="h6" align="center" color="textSecondary" paragraph>
                        Choose size and total copies
                    </Typography>
                    <form className={classes.formView} onSubmit={formik.handleSubmit}>
                        <FormLabel component="legend">Style</FormLabel>
                        <RadioGroup row aria-label="style" name="size"
                            value={formik.values.size} onChange={formik.handleChange}>
                            <FormControlLabel value="small" control={<Radio />} label="Table" />
                            <FormControlLabel value="large" control={<Radio />} label="Large" />
                        </RadioGroup>
                        <TextField variant="outlined" margin="normal" required fullWidth
                            id="count" label="Total Copies" name="count" type="number"
                            disabled={formik.values.size === 'large'}
                            InputProps={{
                                inputProps: { min: minCopies }
                            }}
                            onChange={formik.handleChange} value={formik.values.count} />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={formik.handleSubmit}
                            className={classes.primaryButton}
                        >
                            Print
                        </Button>
                    </form>
                </Grid>
                <Grid container className={classes.gridColumn} item xs={12} sm={6} md={6} spacing={2} elevation={2} square>
                    <Typography variant="h6" align="left" color="textSecondary">
                        Preview
                    </Typography>
                    {formik.values.size === 'small' &&
                        <div className={classes.qrContainer}>
                            <ScanCodeSmall ref={componentRef} link={restaurantUrl} count={formik.values.count} qrView={classes.qrView} />
                        </div>
                    }
                    {formik.values.size === 'large' &&
                        <div className={classes.qrContainer}>
                            <ScanCodeLarge ref={componentRef} link={restaurantUrl} count={formik.values.count} qrView={classes.qrView} />
                        </div>
                    }
                </Grid>
            </Grid>
            
         
        </div>
    );
}

export default connect(null, appActions)(ForYou);